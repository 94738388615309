import React from "react"

import Layout from "../components/layout"
import SEO from "../utils/seo"
import Access from "../components/access"

const MobileAccess = () => (
  <Layout title="Aula Virtual">
    <SEO title="Aula Virtual" />
    <br />
    <br />
    <br />
    <br />
    <Access />
  </Layout>
)

export default MobileAccess
